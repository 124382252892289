import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Edit2, Trash } from "react-feather";

import Table, { TableHeading } from "../../../../shared/Table/Table";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  GetOurTeams,
  deleteTeamApi,
  getExperts,
  updateOrder,
} from "../../../../store/features/AdminPanel/teams/ourTeamsApi";

import "./ourExpertTable.scss";
import {
  changeTempOrder,
  editTeamsInfo,
} from "../../../../store/features/AdminPanel/teams/ourTeamsSlice";
import Empty from "../../../../shared/Empty/Empty";

const OurExpertTable = () => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [teamId, setTeamId] = React.useState<any>("");
  const dispatch = useAppDispatch();
  const ourTeamsState: any = useAppSelector((store: RootState) => {
    return store.OurTeamsSlice;
  });

  const deleteModalHandler = (teamId: any) => {
    setDeleteShowModal(true);
    setTeamId(teamId);
  };

  useEffect(() => {
    dispatch(getExperts());
  }, []);
  const deleteTeam = () => {
    dispatch(deleteTeamApi(teamId));
  };
  const editTeam = (team: any) => {
    dispatch(editTeamsInfo(team));
  };
  useEffect(() => {
    if (ourTeamsState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [ourTeamsState.req_success.delete]);
  const listenDrag = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //
    const data2 = {
      changeOrder: true,
      sourdeIndex: source.index,
      destinationIndex: destination.index,
      draggableId: draggableId,
    };
    dispatch(changeTempOrder(data2));
    const data = { list: { [draggableId]: destination.index + 1 } };
    dispatch(updateOrder({ data: data }));
  };
  return (
    <div className="team_talbe_container">
      <DragDropContext onDragEnd={listenDrag}>
        <Droppable droppableId="239999">
          {(provided) => (
            <Table
              heading={
                <TableHeading>
                  <th>Name</th>
                  <th>Designation</th>
                  {/* <th>Member Type</th> */}
                  <th className="hide__text">. </th>
                  <th className="hide__text">. </th>
                </TableHeading>
              }
            >
              <tbody ref={provided.innerRef} className="team_tbody">
                {!ourTeamsState.loading.get &&
                  ourTeamsState.req_success.get &&
                  ourTeamsState.teams.map((team: any, index: number) => {
                    return (
                      <Draggable
                        draggableId={team.id.toString()}
                        key={team.id.toString()}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <tr
                              className="table_row_text"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <td className="user_td_section">
                                <div className="user_section_team">
                                  <img
                                    className="user_img"
                                    src={team.avatar}
                                    alt="team"
                                  />

                                  <div className="user_name_sec">
                                    <span>{team?.name}</span>
                                    {/* <span className="linked_in_link">
                                      {team?.linkedin}
                                    </span> */}
                                  </div>
                                </div>
                              </td>
                              <td className="team_designation__section">
                                {team?.designation}
                              </td>
                              <td className="team_member__section hide__text">
                                .
                              </td>

                              <td className="drag__team__action__icons ">
                                <div className="action__section__action">
                                  <div className="drag_test_4544">
                                    {" "}
                                    <Edit2
                                      className="edit__icon"
                                      onClick={() => editTeam(team)}
                                    />
                                    <Trash
                                      className="delete__icon"
                                      onClick={() =>
                                        deleteModalHandler(team.id)
                                      }
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      {!ourTeamsState.loading.get &&
        ourTeamsState.req_success.get &&
        ourTeamsState.teams.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty
              emptyText="It seems empty."
              emptyColorFulText="Add expert"
              emptyText2="now."
              custombg={true}
            />
          </div>
        )}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={ourTeamsState.loading.delete}
        onClick={deleteTeam}
      />
    </div>
  );
};

export default OurExpertTable;
