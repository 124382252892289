/* eslint-disable no-useless-escape */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./ourExperts.scss";
import {
  addTeamApi,
  updateTeamApi,
} from "../../../store/features/AdminPanel/teams/ourTeamsApi";
import {
  useAppDispatch,
  useAppSelector,
  RootState,
} from "../../../store/store";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import { getBase64, getFileExtension } from "../../../helpers/utils/FileHelper";
import {
  closeTeamEditModal,
  searchTeamsInTable,
} from "../../../store/features/AdminPanel/teams/ourTeamsSlice";
import ErrorText from "../../../shared/Inputs/ErrorText/ErrorText";

const OurExpertsHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const [imageUrl, setImageUrl] = useState("");
  const [imageExt, setImageExt] = useState("");
  const [searchText, setSearchText] = useState("");
  const [imageError, setImageError] = useState("");
  const hiddenFileInput = useRef<any>(null);


  const dispatch = useAppDispatch();

  const ourTeamsState: any = useAppSelector((store: RootState) => {
    return store.OurTeamsSlice;
  });

  const handleClick = (event: any) => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const removeImage = () => {
    setImageUrl("");
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Name")),
    designation: yup.string().required(REQUIRED_VALIDATION("Designation")),

  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];

    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  useEffect(() => {
    dispatch(searchTeamsInTable(searchText));
  }, [searchText]);
  const clearForm = () => {
    reset();
    setImageUrl("");
    setImageExt("");
    setImageFile("");
    setImageError("");
  };

  const onSubmit = (values: any) => {
    
    if (!ourTeamsState.editTeam) {
      if (!imageFile) {
        setImageError("Image is required");
        return;
      }

      const data = {
        ...values,
        member_type:"instructor",
        avatar: imageFile,
        extension: imageExt,
      };
      dispatch(addTeamApi(data));
    }
    if (ourTeamsState.editTeam) {

      let data;
      if (imageFile) {
        data = {
          ...values,
          member_type: "instructor",
          avatar: imageFile,
          extension: imageExt,
          _method: "put",
        };
      }
      if (!imageFile) {
        data = {
          ...values,
          member_type: "instructor",
          _method: "put",
        };
      }

      dispatch(
        updateTeamApi({
          teamId: ourTeamsState.single_team_info?.id,
          data: data,
        }),
      );
    }
  };
  const handleModal = () => {
    dispatch(closeTeamEditModal(false));
    clearForm();
  };
  useEffect(() => {
    if (ourTeamsState.req_success.add) {
      setShowModal(false);
      clearForm();
    }
  }, [ourTeamsState.req_success.add]);


  useEffect(() => {
    if (ourTeamsState.editTeam) {
      setShowModal(true);
      setValue("name", ourTeamsState.single_team_info.name);
      setValue("designation", ourTeamsState.single_team_info.designation);
      // setValue("linkedin", ourTeamsState.single_team_info.linkedin);
      // setValue("order", ourTeamsState.single_team_info.order);
      setImageUrl(ourTeamsState.single_team_info?.avatar);
    }
  }, [ourTeamsState?.editTeam]);

  return (
    <div className="team__header__container">
      <div className="left__section">
        <TableHeadingTitle>Our Experts</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        onClick={handleModal}
      >
        <ModalHeader
          title={
            ourTeamsState.editTeam ? "Update Expert" : "Add Expert"
          }
        />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <TextField
              type="text"
              label="Expert Name"
              name="name"
              register={register}
              error={errors?.name}
            />

            <TextField
              type="text"
              label="Designation"
              name="designation"
              register={register}
              error={errors?.designation}
            />


            <div className="team_row_section">

              <div className="team_right_section">
                <span className="team_type_title">Avatar </span>
                <div className="upload_avatar_sec">
                  <img
                    className="author_img"
                    src={imageUrl || "https://via.placeholder.com/150"}
                    alt="avatar"
                  />
                  <div className="image__funcanality">
                    <span className="upload_image" onClick={handleClick}>
                      Upload new avatar
                    </span>
                    {!ourTeamsState.editTeam && (
                      <span className="remove_image" onClick={removeImage}>
                        Remove
                      </span>
                    )}
                  </div>
                </div>
                <ErrorText error={imageError} />

                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  ref={hiddenFileInput}
                  onChange={(e: any) => getFiles(e)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <span className="height" />

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={ourTeamsState.editTeam ? "Update" : "Add"}
                type="submit"
                loading={ourTeamsState.loading.add}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OurExpertsHeader;
