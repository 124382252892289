import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "react-feather";

import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/SignUp";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import VerifyYourEmail from "../pages/Auth/VerifyYourEmail";
import ResetPassword from "../pages/Auth/ResetPassword";

// eslint-disable-next-line import/no-named-as-default
import AdminRoute from "./Middleware/AdminRoute";
import PrivateRoute from "./Middleware/PrivateRoute";
import CheckAuthRoute from "./Middleware/CheckAuthRoute";

import OurTeam from "../pages/Landingpage/OurTeam/OurTeam";
import Contact from "../pages/Landingpage/Contact/Contact";
import Error404 from "../pages/ErrorPage/Error404";

import TenantRoute from "./Middleware/TenantRoute";
import TenantLoading from "../pages/Auth/TenantLoading";
import MemberSignup from "../pages/Auth/MemberSignup";
import TenantUserRoute from "./Middleware/TenantUserRoute";
import DefaultPasswordChange from "../pages/Auth/DefaultPasswordChange";

// import LearningResources from "./pages/NewAcademicUser/LearningResources/LearningResources";
// import SingleLearningResource from "./pages/NewAcademicUser/LearningResources/SingleLearningResource";
import MentorRoute from "./Middleware/MentorRoute";
import MentorInterview from "../pages/AdminPanel/AdminInterview/MentorInterview";
import AllAdminRouteList from "./Middleware/AllAdminRouteList";
import AllPrivateRoute from "./AllPrivateRoute";
import AllTenantRoute from "./AllTenantRoute";
import AllTenantUserRoute from "./AllTenantUserRoute";

import { RootStore, useAppSelector } from "../store/store";
import CheckRouteAccess from "../HOC/CheckRouteAccess";
import { ROUTES } from "../helpers/utils/Constants/route";
import GoogleLoginComponent from "../pages/Auth/GoogleLoginComponent";
import { PREMIUM_COURSES_ROUTES, ROUTES_ONLY_FOR_TAC_PRODUCT_MANAGER } from "./DomainSpecific/RoutesOnlyForTAC";
import TacPrivateRoute from "./Middleware/TacPrivateRoute";
import ProfileRoute from "./Middleware/ProfileRoute";
import UserProfile from "../pages/AdminPanel/User/profile";
import TenantOwnerProfile from "../pages/Orgination/OwnerProfile/TenantOwnerProfile";
import DoubleLogin from "../pages/Auth/DoubleLogin";
import PayToGetAccess from "../pages/Auth/PayToGetAccess";
import ProductPagesDateTime from "../pages/AdminPanel/ProductPagesDateTime/ProductPagesDateTime";
import ApplyNow from "../pages/AdminPanel/ApplyNow/ApplyNow";
import ViewDetails from "../pages/AdminPanel/ApplyNow/ViewDetails";
import TrialUserCourseDetail from "../pages/TrialUser/CourseDetail/TrialUserCourseDetail";
import TrialUserRoute from "./Middleware/TrialUserRoute";
import TrailUserProfile from "../pages/TrialUser/Profile/TrailUserProfile";
import AdminAndSubAdminRoute from "./Middleware/AdminAndSubAdminRoute";
import AllAdminAndSubAdminRouteList from "./Middleware/AllAdminAndSubAdminRouteList";
import ProductPageTestimonial from "../pages/AdminPanel/ProductPageTestimonial/ProductPageTestimonial";
import OurClasses from "../pages/AdminPanel/OurClasses/OurClasses";
import SubsCriber from "../pages/AdminPanel/Subscriber/SubsCriber";
import VideoTour from "../pages/AdminPanel/VideoTour/VideoTour";
import UpcomingSessionsUsers from "../pages/AdminPanel/UpcomingSessionsUsers/UpcomingSessionsUsers";
import TrainingAGroup from "../pages/AdminPanel/TrainingAGroup/TrainingAGroup";
import OurExperts from "../pages/AdminPanel/OurExperts/OurExperts";
import LpCohorts from "../pages/AdminPanel/LpCohorts/LpCohorts";
// import UserProfile from "../../../pages/AdminPanel/User/profile";

const AllRoutes = () => {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  return (
    <Suspense
      fallback={
        <div className="loader-div">
          <Loader />
        </div>
      }
    >
      <Routes>
        <Route
          path={ROUTES.LOGIN}
          element={
            <CheckAuthRoute>
              <Login />
            </CheckAuthRoute>
          }
        />
        <Route
          path={ROUTES.LOGIN2}
          element={
            <CheckAuthRoute>
              <Login />
            </CheckAuthRoute>
          }
        />
        <Route path={ROUTES.SINGUP} element={<SignUp />} />
        <Route
          path={ROUTES.DEFAULT_PASSWORD_CHANGE}
          element={<DefaultPasswordChange />}
        />
        <Route path="/:token/:email/:role" element={<GoogleLoginComponent />} />
        <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.VERIFY_YOUR_EMAIL} element={<VerifyYourEmail />} />
        <Route path={ROUTES.TENANT_LOADING} element={<TenantLoading />} />
        <Route path={ROUTES.MEMBER_SIGNUP} element={<MemberSignup />} />

        <Route element={<TrialUserRoute />}>
          <Route
            path={ROUTES.TRIAL_USER_COURSE}
            element={<TrialUserCourseDetail />}
          />
          <Route path={ROUTES.MY_PROFIL} element={<TrailUserProfile />} />
        </Route>

        <Route element={<TacPrivateRoute />}>
          {ROUTES_ONLY_FOR_TAC_PRODUCT_MANAGER.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route element={<TacPrivateRoute />}>
          {PREMIUM_COURSES_ROUTES.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
        {CheckRouteAccess(configState?.configs?.membership_enabled)}
        {/* this route for system admin  and their role === sys_adm */}
        <Route element={<AdminRoute />}>
          {AllAdminRouteList.map((route: any) => {
            if (
              route.domain.includes(configState.configs?.membership_enabled)
            ) {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<route.component />}
                />
              );
            }
            return null;
          })}
        </Route>

        {/* this route for system admin  and sub admin */}
        <Route element={<AdminAndSubAdminRoute />}>
          {AllAdminAndSubAdminRouteList.map((route: any) => {
            if (
              route.domain.includes(configState.configs?.membership_enabled)
            ) {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<route.component />}
                />
              );
            }
            return null;
          })}
        </Route>
        <Route element={<ProfileRoute />}>
          <Route path={ROUTES.PROFILE} element={<UserProfile />} />
          <Route
            path={`${ROUTES.TENANT_OWNER_PROFILE}/:tag`}
            element={<TenantOwnerProfile />}
          />
        </Route>
        {/* this route for academic user or normal user  and their role === user */}
        <Route element={<PrivateRoute />}>
          {AllPrivateRoute.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
        {/* this route for tenant admin or organization  and their role === admin or owner */}
        <Route element={<TenantRoute />}>
          {AllTenantRoute.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
          <Route path="pay" element={<PayToGetAccess />} />
        </Route>
        {/* this route for tenant student or organization student  and their role === student */}
        <Route element={<TenantUserRoute />}>
          {AllTenantUserRoute.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
          <Route path="pay" element={<PayToGetAccess />} />
        </Route>
        {/* this page is just for  user */}
        {/* <Route
        path="/my-certificates"
        element={
          <PrivateRoute>
            <Certificates />
          </PrivateRoute>
        }
      /> */}
        <Route
          path={`${ROUTES.MENTOR_INTERVIEW}/:tag`}
          element={
            <MentorRoute>
              <MentorInterview />
            </MentorRoute>
          }
        />
        {/* Index */}
        <Route path={ROUTES.OUR_TEAMS} element={<OurTeam />} />
        <Route path="double-setup" element={<DoubleLogin />} />
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route
          path={ROUTES.PRODUCT_PAGES_DATE_TIME}
          element={<ProductPagesDateTime />}
        />
        <Route path={ROUTES.APPLY_NOW} element={<ApplyNow />} />
        <Route path={ROUTES.PRODUCT_PAGE_TESTIMONIAL} element={<ProductPageTestimonial />} />
        <Route path={ROUTES.OUR_CLASSES} element={<OurClasses />} />
        <Route path={ROUTES.VIEW_DETAILS} element={<ViewDetails />} />
        <Route path={ROUTES.SUBSCRIBER} element={<SubsCriber />} />
        <Route path={ROUTES.VIDEO_TOUR} element={<VideoTour />} />
        <Route path={ROUTES.UPCOMING_SESSIONS_USERS} element={<UpcomingSessionsUsers />} />
        <Route path={ROUTES.TRAINING_GROUP_USERS} element={<TrainingAGroup />} />
        <Route path={ROUTES.OUR_EXPERTS} element={<OurExperts />} />
        <Route path={ROUTES.COHORTS} element={<LpCohorts />} />
        
        <Route path="*" element={<Error404 />} />
        {/* <Route path="/admin-learningpath" element={<AdminLearningPath />} /> */}
        {/* <Route
        path="/admin-courses-detail"
        element={<OrgUserCourseDetailPage />}
      /> */}
        {/* <Route
        path="/admin-learningpath-courses"
        element={<OrgUserLearningPathCoursePage />}
      /> */}
        {/* <Route path="/groups" element={<Groups />} /> */}
        {/* orgination route   */}
        {/* orgination user route */}
        {/* Admin interview prep routes  */}
      </Routes>
    </Suspense>
  );
};

export default AllRoutes;
